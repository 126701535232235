.hill-strategy-container {
    text-align: center;

    .update-progress {
        margin-left: 8px;
    }
}

.hill-strategy-dialog {
    text-align: center;

    .stay-strategy-button,
    .leave-strategy-button,
    .stay-until-strategy-button {
        font-size: 12px;
    }

    .stay-strategy-button {
        margin-bottom: 14px;
    }

    .leave-strategy-button {
        margin-bottom: 8px;
    }

    .cancel-button {
        margin: 6px 20px;
    }
}
