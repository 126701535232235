.item-card {
    margin: 8px;
    padding: 10px;
    width: 170px;
    min-height: 210px;
    text-align: center;

    .card-title {
        font-size: 15px;
    }

    .card-content {
        margin-top: 2px;
        font-size: 13px;
    }

    button {
        margin-top: 6px;
    }
}