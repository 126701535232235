.hearts-container {
    zoom: 65%;
}

.item {
    margin: 0;
    padding: 6px 4px;

    .item-title {
        display: flex;

        .MuiIcon-root {
            font-size: 1.3rem;
            line-height: 1rem;
            margin-right: 4px;
        }
    }

    .item-description {
        font-size: 12px;
    }
}